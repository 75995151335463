import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import {
	routerReducer, RouterReducerState, StoreRouterConnectingModule, FullRouterStateSerializer,
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CookieService } from 'ngx-cookie-service';
import { StoreModule } from '@ngrx/store';
// import { ZonarUiI18nModule } from '@zonar-ui/i18n';
import { WorkerModule } from 'angular-web-worker/angular';
import { HeaderModule } from './components/header/header.module';
import {environment, httpInterceptList} from '../environments/environment'; // Angular CLI environemnt
import { ErrorModule } from './components/error-page/error.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';

import { ApiModule } from './api/api.module';
import { InfoAlertModule } from './components/info-alert/info-alert.module';
import { HighchartModule } from './components/highchart/highchart.module';
import { DataTableModule } from './components/data-table/data-table.module';
import { MaterialModule } from './material/material.module';
import { HoursReducer, HoursState, initialHoursState } from './state/hours/hours.reducer';
import { appReducer, initialState as initialAppState } from './state/app/app.reducer';
import { AppState } from './state/app/app-types';
import { loadingStateReducer, State as LiveStatusState, initialState as initialLiveStatusState } from './state/live-status/live-status.reducer';
import { availabilityReducer, initialState as initialAvailabilityState } from './state/availability/availability.reducer';
import { SdsReducer } from './state/sds/sds.reducer';
import { SdsState } from './state/sds/sds.types';
import { AvailabilityDetails } from './state/availability/availability.types';
import {
	hosViolationsLiveStatusReducer,
	initialViolationsLiveStatusState as initialVioLiveStatusState
} from './state/hos-violations/hos-violations.reducer';
import {HosViolationsLiveStatusState } from './reports/hos-violations/hos-violation-types';

import { UserService } from './components/user/user.service';

import { LiveStatusService } from './state/live-status/live-status.service';

import { GetAppEffects } from './state/app/app.effect';
import { BaseService } from './api/base-service';

import { SdsEffects } from './state/sds/sds.effects';
import { SdsService } from './state/sds/sds.service';
import { SdsWorker } from './state/sds/sds.worker';
import { PipesModule } from './pipes/pipes.module';
import { HoursEffects } from './state/hours/hours.effects';
import { NgxPendoModule } from 'ngx-pendo';
import { HoursWorker } from './state/hours/hours.worker';
import { HoursService } from './state/hours/hours.service';
import { OdometerEffects } from './state/odometer/odometer.effects';
import { OdometerState } from './state/odometer/odometer.types';
import { odometerReducer } from './state/odometer/odometer.reducer';
import { OdometerWorker } from './state/odometer/odometer.worker';
import { initialSdsState } from './state/sds/sds.utils';
import { initialOdometerState } from './state/odometer/odometer.utils';
import { OdometerService } from './state/odometer/odometer.service';
import { SideBarModule } from './components/sidebar/sidebar.module';
import {
	ZonarUiAuthModule,
	ZonarUiPermissionsModule,
	ZonarOwnerIdInterceptor,
	NoPermissionRoutes,
} from '@zonar-ui/auth';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import {AuthGuard, AuthHttpInterceptor, AuthModule, AuthService } from '@auth0/auth0-angular';
import {appNameForPerm, permissions} from './../environments/shared';
import { AuthInterceptor } from './auth/auth/auth.interceptor';
import { AppRootComponent } from './app-root.component';
import {MalfunctionsModule} from './reports/malfunctions/malfunctions.module';
import { ZonarUICoreModule } from '@zonar-ui/core';
import {HOS_APP_DEV_CYCLE_CLIENT_ID, HOS_APP_NAME, HOS_ENV, ZonarUiHosNavigationModule} from '@zonar-ui/hos-navigation';
import { ZonarUiFooterModule } from '@zonar-ui/footer';
import {ZonarUiI18nModule, TranslateModule} from "@zonar-ui/i18n";
import {DispatcherLocationToggleService} from "./dispatcherLocationToggle.service";
import {SidenavModule} from '@zonar-ui/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {DispatcherListModule} from "./components/dispatcher-list/dispatcher-list.module";

export const ROOT_STORE = StoreModule.forRoot({
	router: routerReducer,
	app: appReducer,
	liveStatus: loadingStateReducer,
	availability: availabilityReducer,
	hosViolationsLiveStatus: hosViolationsLiveStatusReducer,
	sds: SdsReducer,
	hours: HoursReducer,
	odometer: odometerReducer,
}, {
	runtimeChecks: {
		strictStateSerializability: false,
		strictActionSerializability: false,
		strictActionImmutability: false,
		strictStateImmutability: false,
	},
});

export const RootInitialState = {
	app: initialAppState,
	liveStatus: initialLiveStatusState,
	availability: initialAvailabilityState,
	hosViolationsLiveStatus: initialVioLiveStatusState,
	sds: initialSdsState,
	hours: initialHoursState,
	odometer: initialOdometerState,
};


export interface RootState {
	router: RouterReducerState;
	app: AppState;
	liveStatus: LiveStatusState;
	availability: AvailabilityDetails;
	hosViolationsLiveStatus: HosViolationsLiveStatusState;
	sds: SdsState;
	hours: HoursState;
	odometer: OdometerState;
}

@NgModule({
	declarations: [
		AppComponent,
		AppRootComponent,
	],
	imports: [
		NoPermissionRoutes,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
		AuthModule.forRoot({
			clientId: environment.auth.clientID,
			domain: environment.auth.domain,
			audience: environment.auth.audience,
			redirect_uri: window.location.origin,
			scope: 'openid profile email',
			useRefreshTokens: true,
			max_age: 36000,
			cacheLocation: 'localstorage',
			httpInterceptor: {
				allowedList: httpInterceptList
			}
		}),
		HeaderModule,
		DispatcherListModule,
		ZonarUIMaterialModule,
		ErrorModule,
		MaterialModule,
		HighchartModule,
		ApiModule,
		InfoAlertModule,
		PipesModule,
		DataTableModule,
		SideBarModule,
		TranslateModule.forRoot({}),
		// ZonarUiI18nModule,
		WorkerModule.forWorkers([
			{ worker: SdsWorker, initFn: () => new Worker(new URL('./state/sds/sds.worker.ts', import.meta.url), { type: 'module' }) },
			{ worker: HoursWorker, initFn: () => new Worker(new URL('./state/hours/hours.worker.ts', import.meta.url), { type: 'module' }) },
			{ worker: OdometerWorker, initFn: () => new Worker(new URL('./state/odometer/odometer.worker.ts', import.meta.url), { type: 'module' }) },
		]),
		NgxPendoModule.forRoot({
			pendoApiKey: '47e63a49-4477-47c2-4ea6-0f032e1785d5',
			pendoIdFormatter: (value: any) => value.toString().toLowerCase(),
		}),
		AppRoutingModule,
		EffectsModule.forRoot([
			// GetLiveStatusEffects,
			GetAppEffects,
			// GetAvailabilityEffects,
			// GetHosViolationsEffects,
			SdsEffects,
			HoursEffects,
			OdometerEffects,
		]),
		ROOT_STORE,
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
		}),
		ZonarUiAuthModule,
		ZonarUiPermissionsModule,
		ZonarUiProgressSpinnerModule,
		MalfunctionsModule,
		ZonarUICoreModule,
		ZonarUiFooterModule,
		TranslateModule.forRoot({}),
		ZonarUiI18nModule,
		SidenavModule.forRoot({stage: environment.authEnv}),
		MatIconModule,
		ZonarUiHosNavigationModule,
	],
	exports: [
		RouterModule,
	],
	providers: [
		AuthGuard,
		AuthService,
		BaseService,
		CookieService,
		LiveStatusService,
		OdometerService,
		SdsService,
		HoursService,
		UserService,
		DispatcherLocationToggleService,
		NoPermissionRoutes,
		{provide: 'applicationId', useValue: environment.auth.applicationId},
		{provide: HOS_ENV, useValue: environment.name},
		{provide: HOS_APP_NAME, useValue: appNameForPerm},
		{provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix},
		{provide: 'routePermissions', useValue: permissions.routes},
		{provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl},
		{provide: 'logoutReturnToUrl', useValue: window.location.origin },
		{provide: 'environment', useValue: environment.authEnv},
		{provide: 'region', useValue: environment.region },	
		{provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true}, //causes circlar dependency and error
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{provide: 'supportedLanguages', useValue: ['en-US']},
		{provide: 'defaultLanguage', useValue: 'en-US'},
		{
			provide: HOS_APP_DEV_CYCLE_CLIENT_ID,
			useValue: environment.devCycle.clientId
		},
	],
	bootstrap: [
		AppRootComponent,
	],
})
export class AppModule {
}
