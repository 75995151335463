import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as _ from 'lodash';
import {
	map, mergeMap, pluck, switchMap,
} from 'rxjs/operators';
import { NgxPendoService } from 'ngx-pendo';
import { AppService } from './app.service';
import { Dispatcher, Location, UserData } from './app-types';
import {
	GET_CONTEXTUAL_HELP,
	GET_DISPATCHERS_LIST,
	GET_LOCATIONS_LIST,
	GET_USER_DATA,
	getContextualHelpSuccess,
	getLocationsListSuccess,
	getDispatchersList,
	getDispatchersListSuccess,
	getTimeOptions,
	getUserDataSuccess,
	SET_SELECTED_ENTITY,
	SET_SELECTED_TIME_OPTION,
	SET_TIME_OPTIONS,
	GET_ASSETS_OPTIONS_LIST,
	getAssetOptionsSuccesss,
	GET_DRIVERS_OPTIONS_LIST,
	getDriverOptionsSuccess,
	GET_LOCATIONS_OPTIONS_LIST,
	getLocationOptionsSuccess,
} from './app.actions';

const PERSISTENT_PARAMETERS = ['beginDate', 'endDate', 'sdsType', 'tableMalfunctionType', 'tableStartDate', 'tableEndDate'];

@Injectable()
export class GetAppEffects {
	// SetEntity = createEffect(() => this.actions$.pipe(ofType(SET_SELECTED_ENTITY), map(() => getDispatchersList({ reload: true }))));

	SetTimeOptions = createEffect(() => this.actions$.pipe(ofType(SET_TIME_OPTIONS),
		map(() => getTimeOptions({
			payload: this.appService.getTimeOptions('day', null),
		}))));

	SetSelectedTimeOptions = createEffect(() => this.actions$.pipe(ofType(SET_SELECTED_TIME_OPTION),
		map((days) => getTimeOptions({
			payload: this.appService.getTimeOptions('day', { name: 'days', code: days }),
		}))));

	GetDispatchersList = createEffect(() => this.actions$.pipe(ofType(GET_DISPATCHERS_LIST),
		mergeMap(
			(action: any) => this.appService.getDispatchers().pipe(
				map((dispatchers: any) => {
					if (action.reload) {
						window.history.pushState(null, null, this.filterUrlParams(PERSISTENT_PARAMETERS, window));
						window.location.reload();
					}
					const modDispatchers = dispatchers.dispatchers.map((l) => (
						{
							id: `${dispatchers.account}:${l.id}`,
							label: `${l.fname} ${l.lname}`
						}));

					return getDispatchersListSuccess({ dispatchers: _.sortBy(modDispatchers, 'label') });
				}),
			),
		)));

	GetUserData = createEffect(
		() => this.actions$.pipe(ofType(GET_USER_DATA),
			mergeMap(
				() => this.appService.loadUserData().pipe(
					map((userData: UserData) => {
						try {
							console.log('in get user data effect');
						} catch {
							console.log('adblock detected');
						}
						return getUserDataSuccess(userData);
					}),
				),

			)),
	);

	//todo: this populates the location dropdown used in overview
	GetLocationsList = createEffect(() => this.actions$.pipe(ofType(GET_LOCATIONS_LIST),
		mergeMap(
			() => this.appService.loadLocationOptions().pipe(
				map((locations: any) => {
					const modLocations = locations.locations.map((l) => ({id: `${locations.account}:${l.id}`, label: l.name}));
					return getLocationsListSuccess({ locations: _.sortBy(modLocations, 'label') })
				}),
			),
		)));

	GetAssetsOptions = createEffect(() => this.actions$.pipe(ofType(GET_ASSETS_OPTIONS_LIST),
		mergeMap(
			() => this.appService.loadAssetOptions().pipe(
				map((assets: Array<any>) => getAssetOptionsSuccesss({ assets: _.sortBy(assets, 'id') })),
			),
		)));

	GetDriversOptions = createEffect(() => this.actions$.pipe(ofType(GET_DRIVERS_OPTIONS_LIST),
		mergeMap(
			() => this.appService.loadDriverOptions().pipe(
				map((drivers: Array<any>) => getDriverOptionsSuccess({ drivers: _.sortBy(drivers, 'id') })),
			),
		)));

	GetContextualHelp = createEffect(() => this.actions$.pipe(ofType(GET_CONTEXTUAL_HELP)).pipe(
		mergeMap(({ id, helpType }) => this.appService.getContextualHelp(id, helpType).pipe(
			map((contextualHelpHTML: any) => getContextualHelpSuccess({ payload: contextualHelpHTML })),
		)),
	));

	filterUrlParams(keys, windowContext) {
		let newUrlParams = '?';
		const urlParams = new URLSearchParams(windowContext.location.search);
		_.forEach(keys, (key) => {
			if (urlParams.get(key)) {
				newUrlParams += `&${key}=${urlParams.getAll(key)}`;
			}
		});
		return `${windowContext.location.pathname}${newUrlParams}`;
	}

	constructor(
		protected ngxPendoService: NgxPendoService,
		private actions$: Actions,
		private appService: AppService,
	) { }
}
